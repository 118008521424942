let baseUrl = 'http://localhost:5003/api';
// let baseUrl = 'https://codeaxis-node.asfandyaar.online/api';
let mediaUrl = 'http://localhost:5003';

if (window.location.host === 'asfandyaar.online') {
  baseUrl = 'https://codeaxis-node.asfandyaar.online/api';
  mediaUrl = 'https://codeaxis-node.asfandyaar.online';
} else if (window.location.host === 'dermazon-qa.falconweb.app') {
  baseUrl = 'https://dermazon-api-qa.falconweb.app/api';
  mediaUrl = 'https://dermazon-api-qa.falconweb.app';
} else if (window.location.host === 'dermazon-staging.falconweb.app') {
  baseUrl = 'https://dermazon-api-staging.falconweb.app/api';
  mediaUrl = 'https://dermazon-api-staging.falconweb.app';
} else if (window.location.host === 'dermazon-prod.falconweb.app/') {
  baseUrl = 'https://dermazon-api-prod.falconweb.app/api';
  mediaUrl = 'https://dermazon-api-prod.falconweb.app';
}

module.exports = { baseUrl, mediaUrl };
