import React from 'react';
import Fade from 'react-reveal';
import { Helmet } from 'react-helmet';

import orientator from '../assets/O.png';
import dirasat from '../assets/D.png';
import zypzap from '../assets/Z.png';
import orgalplan from '../assets/ORGA.png';
import quadrabase from '../assets/Q.png';
import myresume from '../assets/MY.png';
import store from '../assets/STORE.png';
import yourvote from '../assets/YourVote.png';
import meduSched from '../assets/medusched.png';
import signwise from '../assets/signwise.png'
import todo from '../assets/Todo.png';

const projectsArray = [
  {
    title: 'Orienator',
    category: 'A web application that allows students to make their best career choice in France.',
    image: orientator,
    url: 'https://orientator.com/',
  },
  {
    title: 'Dirasat',
    category: 'A web application that allows students to make their best career choice in France.',
    image: dirasat,
    url: 'https://www.dirasat-international.com/',
  },
  {
    title: 'zypZap',
    category: 'ZypZap platform for creating, editing and sharing online videos,gaming courses.',
    image: zypzap,
    url: 'https://zypzap.com/',
  },
  {
    title: 'Orgaplan',
    category: 'Orgaplan is a Logistics management web application for the French Construction market.',
    image: orgalplan,
    url: 'https://orgaplansa.com/',
  },
  {
    title: 'Quadrabase',
    category: 'Quadrabase is a project to generate accurate tender documents for client in France',
    image: quadrabase,
    url: 'https://quadrabase.fr/auth/home',
  },
  {
    title: 'My Resume App',
    category: 'My resume application showcasing my expertise, projects, and education.',
    image: myresume,
    url: 'http://asfandyaar.online/',
  },
  {
    title: 'YourVote',
    category: 'A UI design for a voting app in React I made for a university project',
    image: yourvote,
    url: 'https://asfandyar2.github.io/yourvote/#/',
  },
  {
    title: 'Signwise',
    category: 'A web application designed for the industry network to explore and connect with 100 local sign industry businesses for insurance purposes.',
    image: signwise,
    url: 'https://signwise.com/',
  },
  {
    title: 'MeduSched',
    category: "A web applications for students by providing a web app integrated with their affiliated hospital's",
    image: meduSched,
    url: 'https://medusched.com/',
  },
];

const Portfolio = () => {
  const projects = projectsArray.map((project, index) => (
    <div key={index} className="portfolio-item">
      <a href={project.url} target="_blank" rel="noopener noreferrer">
        <div className="item-wrap">
          <div className="project-info">
            <h6>{project.title}</h6>
            <p>{project.category}</p>
          </div>
          <img alt={project.title} src={project.image} />
        </div>
      </a>
    </div>
  ));

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={orientator} />
        <link rel="preload" as="image" href={dirasat} />
        <link rel="preload" as="image" href={zypzap} />
        <link rel="preload" as="image" href={orgalplan} />
        <link rel="preload" as="image" href={quadrabase} />
        <link rel="preload" as="image" href={myresume} />
        <link rel="preload" as="image" href={store} />
        <link rel="preload" as="image" href={yourvote} />
        <link rel="preload" as="image" href={todo} />
      </Helmet>
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="container">
            <h1>Check Out Some of My Works</h1>
            <div className="portfolio-wrapper">{projects}</div>
          </div>
        </Fade>
      </section>
      <style jsx>{`
        #portfolio {
          padding: 60px 0;
          background: #f9f9f9;
        }
        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 15px;
        }
        h1 {
          text-align: center;
          margin-bottom: 40px;
          font-size: 2.5em;
          color: #333;
        }
        .portfolio-wrapper {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 20px;
        }
        .portfolio-item {
          overflow: hidden;
          background: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          transition: transform 0.3s, box-shadow 0.3s;
        }
        .portfolio-item:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }
        .item-wrap {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .project-info {
          padding: 20px;
          text-align: center;
          flex-grow: 1;
          background-color: #f3f3f3;
          border-radius: 8px 8px 0 0;
        }
        .project-info h6 {
          font-size: 1.8em;
          font-weight: 700;
          margin-bottom: 10px;
          color: #333;
        }
        .project-info p {
          font-size: 1.2em;
          font-weight: 500;
          color: #777;
        }
        .item-wrap img {
          width: 100%;
          border-radius: 0 0 8px 8px;
          margin-top: auto;
        }
      `}</style>
    </>
  );
};

export default Portfolio;
