import React, { useState } from 'react';
import { Fade, Slide } from 'react-reveal';
import API from '../api';
import { toast } from 'react-toastify';

const Contact = props => {
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [loading, setLoading] = useState(false);
  if (!props.data) return null;

  const name = props.data.name;
  const street = props.data.address.street;
  const city = props.data.address.city;
  const state = props.data.address.state;
  const zip = props.data.address.zip;
  const phone = props.data.phone;
  const message = props.data.contactmessage;

  const handleChange = e => {
    switch (e.target.id) {
      case 'contactName':
        setContactName(e.target.value);
        break;
      case 'contactEmail':
        setContactEmail(e.target.value);
        break;
      case 'contactSubject':
        setContactSubject(e.target.value);
        break;
      case 'contactMessage':
        setContactMessage(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    const data = {
      name: contactName,
      email: contactEmail,
      subject: contactSubject,
      message: contactMessage
    };

    setLoading(true);
    await API('post', '/users/contact', data)
      .then(res => {
        console.log(res);
        toast.success("Thanks,We'll get back to you.");
      })
      .catch(err => {
        toast.error(err);
      });
    setContactName('');
    setContactEmail('');
    setContactSubject('');
    setContactMessage('');

    setLoading(false);
  };
  console.log(loading);

  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch.</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">
              {message}
            </p>
          </div>
        </div>
      </Fade>
      <div className="row">
        <Slide left duration={1000}>
          <div className="eight columns">
            <form
              onSubmit={
                handleSubmit // name="contactForm" // id="contactForm" // method="post" // action={handleSubmit}
              }
              disabled={loading}
            >
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    required
                    id="contactName"
                    name="contactName"
                    onChange={handleChange}
                    value={contactName}
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    required
                    id="contactEmail"
                    name="contactEmail"
                    onChange={handleChange}
                    value={contactEmail}
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">
                    Subject <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    required
                    id="contactSubject"
                    name="contactSubject"
                    onChange={handleChange}
                    value={contactSubject}
                  />
                </div>

                <div>
                  <label htmlFor="contactMessage">
                    Message <span className="required">*</span>
                  </label>
                  <textarea
                    cols="50"
                    rows="8"
                    required
                    id="contactMessage"
                    name="contactMessage"
                    value={contactMessage}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <button
                    className={`submit ${loading && 'buttonOpacity '} "`}
                    disabled={loading}
                  >
                    Submit
                  </button>
                  {loading &&
                    <span id="image-loadermy">
                      <img alt="" src="images/loader.gif" />
                    </span>}
                </div>
              </fieldset>
            </form>
          </div>
        </Slide>

        <Slide right duration={1000}>
          <aside className="four columns footer-widgets">
            <div className="widget widget_contact">
              <h4>Address and Phone</h4>
              <p className="address">
                {name}
                <br />
                {street} <br />
                {city}, {state} {zip}
                <br />
                <span>{phone}</span>
                <br />
                <span>{"asfi.official@gmail.com"}</span>
              </p>
            </div>

            <div className="widget widget_tweets">
              {/* <h4 className="widget-title">Latest Tweets</h4> */}
              <ul id="twitter">
                <li>
                  <span>
                    {/* This is Photoshop's version of Lorem Ipsum. Proin gravida
                    nibh vel velit auctor aliquet. Aenean sollicitudin, lorem
                    quis bibendum auctor, nisi elit consequat ipsum
                    <a href="./">http://t.co/CGIrdxIlI3</a> */}
                  </span>
                  <b>
                    {/* <a href="./">2 Days Ago</a> */}
                  </b>
                </li>
                <li>
                  <span>
                    {/* Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi
                    <a href="./">http://t.co/CGIrdxIlI3</a> */}
                  </span>
                  <b>
                    {/* <a href="./">3 Days Ago</a> */}
                  </b>
                </li>
              </ul>
            </div>
          </aside>
        </Slide>
      </div>
    </section>
  );
};

export default Contact;
