import React, { Component } from 'react';
import Fade from 'react-reveal';

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function(network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className} />
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">
              <ul className="social-links">
                {networks}
              </ul>

              <ul className="copyright">
                <li>&copy; Copyright 2023 Asfand Yar</li>
                <li>
                  Design by{' '}
                  <a
                    title="Styleshout"
                    href="https://asfandyaar.online/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    codeaxis
                  </a>
                </li>
              </ul>
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open" />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
