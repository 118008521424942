import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Portfolio from './Components/Portfolio';
import { Helmet } from 'react-helmet';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [resumeData, setResumeData] = useState({});

  useEffect(() => {
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);
    getResumeData();
  }, []);

  const getResumeData = () => {
    $.ajax({
      url: './resumeData.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        setResumeData(data);
      },
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  };

  return (
    <div className="App">
      <Helmet>
        <title>Asfand Yar (Software Engineer)</title>
        <meta
          name="description"
          content="Asfand Yar (Software Engineer) MERN Stack Developer."
        />
        <meta
          name="keywords"
          content="Asfand,software engineer,IT engineer, asfand, asfand yar, yar asfand,asfand portfolio,asfand resume,"
        />
        <meta property="og:title" content="__OG_TITLE__" />
        <meta property="og:description" content="__OG_DESCRIPTION__" />
        <meta property="og:url" content="__OG_URL__" />
        <meta property="og:image" content="__OG_IMAGE__" />
      </Helmet>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        transition={Flip}
      />
      <Header data={resumeData.main} />
      <About data={resumeData.main} />
      <Resume data={resumeData.resume} />
      <Portfolio data={resumeData.portfolio} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>
  );
};

export default App;
